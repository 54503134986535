
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import ListItem from "@/components/commons/list/ListItems.vue";
import HeadImg from "@/components/commons/HeadImg.vue";
import Loading from "@/components/commons/Loading.vue";
import Toast from "@/components/toast/index";
import { useRoute } from "vue-router";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
import { getLongStringShow, copyString } from "@/utils/formatString";
import {
  getQueryString,
  getQueriesFromUrl,
  historyReplaceStateQuery,
} from "@/utils/url";
import { useI18n } from "vue-i18n";
import {
  getAccountWallet,
  getAccountWalletContracts,
  getAccountContracts,
  getAccountHistory,
} from "@/model/tracking";
export default defineComponent({
  name: "WalletDetail",
  components: {
    ListItem,
    HeadImg,
    Loading,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const route = useRoute();
    const addr = route?.params?.addr;
    const state = ref();
    const detail = ref();
    const historyList = ref([]);
    const collectionsList = ref([]);
    const selectTab = ref(route.query?.tab || "nfts");
    const displayMore = ref(false);
    const showMore = ref(true);
    const imgWidth = ref("94px");
    const imgNumber = ref(3);
    const pageNum = ref(0);
    const loadNext = ref();
    const descriptionDom = ref();
    const showLoadMore = ref(true);
    const listLoading = ref(false);

    const getAccountWalletContractsList = () => {
      getAccountWalletContracts({
        walletAddr: addr,
        pageNum: pageNum.value,
      }).then((res) => {
        listLoading.value = false;
        if (res?.data?.length <= 0) {
          showLoadMore.value = false;
          return;
        }
        pageNum.value += 1;
        const collections = res?.data || [];
        collections.forEach((element: any, index) => {
          element.pageNum = 0;
          element.pageSize = imgNumber.value * 2;
          element.loading = false;
          element.items = [];
        });
        collectionsList.value = collectionsList.value.concat(collections);
      });
    };
    // getAccountWalletContractsList();
    const getAccountWalletData = () => {
      getAccountWallet({
        walletAddr: addr,
        pageNum: pageNum.value,
      }).then((res) => {
        loading.value = false;
        state.value = res?.data;
        nextTick(() => {
          const dom = descriptionDom.value as HTMLElement;
          if (dom && dom.offsetHeight > 40) {
            showMore.value = false;
            displayMore.value = true;
          }
        });
      });
    };
    getAccountWalletData();

    const getAccountHistoryList = () => {
      getAccountHistory({
        walletAddr: addr,
        pageNum: pageNum.value,
      }).then((res) => {
        listLoading.value = false;
        if (res?.data?.length > 0) {
          pageNum.value += 1;
          historyList.value = historyList.value.concat(res?.data || []);
        } else {
          showLoadMore.value = false;
        }
      });
    };

    const getAccountContractsData = (item) => {
      getAccountContracts({
        walletAddr: addr,
        contractAddr: item?.contract,
        pageNum: item?.pageNum,
        pageSize: item?.pageSize,
      }).then((res) => {
        item.pageNum += 1;
        item.loading = false;
        item.items = item.items.concat(res?.data || []);
      });
    };

    const handleCopyString = (string = "") => {
      copyString(string);
      Toast(t("Copy successfully"));
    };

    const viewMore = (item, index) => {
      item.loading = true;
      getAccountContractsData(item);
    };

    const showClick = (item, index) => {
      if (item?.pageNum == 0) {
        item.loading = true;
        getAccountContractsData(item);
      }
    };
    const getImgWidth = () => {
      let width = Math.floor(
        (window.outerWidth - 64 - 8 * (imgNumber.value - 1)) / imgNumber.value
      );
      while (width > 110) {
        imgNumber.value += 1;
        width = Math.floor(
          (window.outerWidth - 64 - 8 * (imgNumber.value - 1)) / imgNumber.value
        );
      }
      imgWidth.value = width + "px";
    };
    const resetParams = () => {
      listLoading.value = true;
      pageNum.value = 0;
      showLoadMore.value = true;
      historyList.value = [];
      collectionsList.value = [];
    };
    onMounted(() => {
      getImgWidth();
      window.addEventListener("resize", function () {
        getImgWidth();
      });
    });

    watch(selectTab, () => {
      historyReplaceStateQuery({ tab: selectTab.value });
      resetParams();
      getFirstData();
    });

    const getFirstData = () => {
      listLoading.value = true;
      if (selectTab.value === "nfts") {
        getAccountWalletContractsList();
      } else {
        getAccountHistoryList();
      }
    };
    getFirstData();
    let ob: any;
    onMounted(() => {
      ob = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio === 0) return;
          if (selectTab.value === "nfts") {
            getAccountWalletContractsList();
          } else {
            getAccountHistoryList();
          }
        },
        {
          rootMargin: "200px",
          threshold: [0],
        }
      );
      ob.observe(loadNext.value as HTMLElement);
    });
    onBeforeUnmount(() => {
      ob.disconnect();
    });

    return {
      state,
      detail,
      historyList,
      collectionsList,
      selectTab,
      showMore,
      loading,
      kmbNumber,
      getFloat,
      getQueryString,
      getLongStringShow,
      copyString,
      handleCopyString,
      showClick,
      viewMore,
      imgWidth,
      imgNumber,
      showLoadMore,
      loadNext,
      displayMore,
      descriptionDom,
      listLoading,
    };
  },
});
