import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-center text-base text-white whitespace-pre-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: ["\n      w-full\n      h-full\n      fixed\n      overflow-hidden\n      left-0\n      top-0\n      box-border\n      flex\n      justify-center\n      items-center\n      flex-col\n      z-50\n    ", {
      'justify-start': _ctx.topHeight,
      'justify-end': _ctx.bottomHeight,
    }],
        style: {
      'padding-top': _ctx.topHeight ? _ctx.topHeight : '0',
      'padding-bottom': _ctx.bottomHeight ? _ctx.bottomHeight : '0',
    }
      }, [
        (_ctx.html)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "\n        rounded\n        max-w-2/3 max-h-1/2\n        p-2\n        bg-whites-40\n        text-white\n        flex\n        justify-center\n        items-center\n      ",
              innerHTML: _ctx.html
            }, null, 8, ["innerHTML"]))
          : (_openBlock(), _createBlock("div", {
              key: 1,
              class: ["\n        rounded\n        max-w-2/3 max-h-1/2\n        p-2\n        bg-black-70\n        text-white\n        flex\n        justify-center\n        items-center\n      ", {
        'p-6': _ctx.imgSrc,
        'flex-col': _ctx.imgPosition === 'top',
        'flex-col-reverse': _ctx.imgPosition === 'bottom',
        'flex-row': _ctx.imgPosition === 'left',
        'flex-row-reverse': _ctx.imgPosition === 'right',
      }],
              style: {
        width: _ctx.width ? _ctx.width : 'auto',
        height: _ctx.height ? _ctx.height : 'auto',
      }
            }, [
              (_ctx.imgSrc)
                ? (_openBlock(), _createBlock("img", {
                    key: 0,
                    class: [{
          'mb-2': _ctx.imgPosition === 'top',
          'mt-2': _ctx.imgPosition === 'bottom',
          'mr-2': _ctx.imgPosition === 'left',
          'ml-2': _ctx.imgPosition === 'right',
        }, "w-6 h-6 text-white"],
                    src: _ctx.imgSrc
                  }, null, 10, ["src"]))
                : _createCommentVNode("", true),
              _createVNode("span", _hoisted_1, _toDisplayString(_ctx.message), 1)
            ], 6))
      ], 6))
    : _createCommentVNode("", true)
}